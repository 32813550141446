import React from 'react';
import Img1 from './assets/images/event1.jpeg';
import Img2 from './assets/images/event2.jpg';
import Img3 from './assets/images/event3.jpg';

function Events() {
  // Example data for events
  const events = [
    {
      title: 'Chat AI',
      date: 'November 20, 2024',
      description: 'Chat about AI with like-minded individuals and learn about the latest advancements in the field.',
      image: Img1, // Use imported image
    },
    {
      title: 'Thanksgiving Break',
      date: 'November 28-December 1, 2024',
      description: 'Take a break to celebrate Thanksgiving with family and friends. Enjoy good food, relaxation, and gratitude!',
      image: Img2,
    },
    {
      title: 'Guest Speaker: Summer Internship Experience',
      date: 'December 3, 2024',
      description: 'Join us for an inspiring talk by a fellow member as they share their journey, challenges, and insights gained during their summer internship at Ascential Technologies.',
      image: Img3,
    },    
  ];

  return (
    <div className="my-8 px-4 lg:px-16">
      <h2 className="text-3xl text-white font-bold mb-6">Upcoming Events</h2>
      <div className="grid gap-6 md:grid-cols-2 lg:grid-cols-3">
        {events.map((event, index) => (
          <div key={index} className="bg-white rounded-lg shadow-md overflow-hidden">
            <img
              src={event.image}
              alt={event.title}
              className="w-full h-40 object-cover"
            />
            <div className="p-4">
              <h3 className="text-xl font-semibold mb-2">{event.title}</h3>
              <p className="text-gray-600 mb-2">{event.date}</p>
              <p className="text-gray-700 mb-4">{event.description}</p>
              <button className="px-4 py-2 bg-orange-500 text-white rounded-lg hover:bg-orange-700 hover:font-bold">
                Learn More
              </button>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
}

export default Events;
