import React from "react";

function SponsorsPartners() {
  return (
    <div
      className="relative py-8 bg-cyan-600 bg-opacity-90"
      style={{
        backgroundSize: "cover",
        backgroundPosition: "center",
        backgroundRepeat: "no-repeat",
      }}
    >

      {/* Content Section */}
      <div className="relative max-w-4xl mx-auto bg-white shadow-lg rounded-lg p-6">
        <h1 className="text-4xl font-bold mb-4 text-center text-orange-500">
          Sponsors & Partners
        </h1>

        {/* Collaboration Section */}
        <section className="mb-8">
          <h2 className="text-2xl font-semibold mb-2">Collaboration</h2>
          <p className="mb-4">
            We are happy to have collaborated with the{" "}
            <strong>Game Dev Club</strong> at CSUSM. Thanks to their support, we
            have secured a room, ACD 209, for our meetings every Wednesday until
            December 14th. This partnership allows us to continue blending AI
            and game development, offering our members unique opportunities to
            learn and innovate.
          </p>
        </section>

        {/* Donation Section */}
        <section className="mb-8">
          <h2 className="text-2xl font-semibold mb-2">Support Our Club</h2>
          <p className="mb-4">
            While we currently do not have any official sponsors, your support
            can help us grow and make a difference! If you would like to
            contribute to the CSUSM AI Club, please consider making a donation.
          </p>
          <a
            href="https://www.example.com/donation-link" // Replace with actual donation link
            target="_blank"
            rel="noopener noreferrer"
            className="inline-block px-6 py-3 bg-orange-500 text-white font-bold rounded-lg hover:bg-orange-600"
          >
            Donate Now
          </a>
        </section>

        {/* Placeholder for Future Sponsors */}
        <section>
          <h2 className="text-2xl font-semibold mb-2">Our Future Sponsors</h2>
          <p className="mb-4">
            We are excited to work with potential sponsors who share our vision
            of advancing AI and fostering innovation within the community. If
            you’re interested in partnering with us, please get in touch!
          </p>
          <a
            href="mailto:csusmaiclub@gmail.com" // Replace with your club's contact email
            className="inline-block px-6 py-3 bg-orange-500 text-white font-bold rounded-lg hover:bg-orange-600"
          >
            Contact Us
          </a>
        </section>
      </div>
    </div>
  );
}

export default SponsorsPartners;
