import React from "react";

// Example data for projects
const projectsData = {
  featuredProjects: [
    {
      title: "Form a Team for Kaggle Competitions",
      description:
        "Collaborate with club members to compete in Kaggle competitions and work on datasets involving Machine Learning (ML) or Deep Learning (DL).",
      problemStatement:
        "Students often lack hands-on experience in real-world datasets and collaborative projects.",
      solution:
        "We are forming teams to participate in Kaggle competitions, fostering collaboration, skill development, and practical experience.",
      outcome:
        "Build ML/DL models, gain exposure to competitive data science, and improve teamwork skills.",
      videoLink: "", // Add a demo video link if available
    },
  ],
  currentProjects: [
    {
      name: "Improving the AI Club Website",
      overview:
        "Enhancing the website’s design, functionality, and user experience to better showcase the club’s mission, projects, and opportunities.",
      status: "In Progress",
      opportunities:
        "Reach out to us to suggest your ideas or contribute to the website’s development.",
      contact: "mailto:csusmaiclub@gmail.com",
    },
    {
      name: "Highlight Member AI Projects",
      overview:
        "If you are a member currently working on an AI project, let us know, and we will showcase it here!",
      status: "Open to Submissions",
      opportunities:
        "Email us to share details about your project and be featured on the site.",
      contact: "mailto:csusmaiclub@gmail.com",
    },
  ],
  pastProjects: [
    {
      title: "LLM Setup and GUI for Local Models",
      summary:
        "We demonstrated how to set up a local large language model (LLM) using Ollama and provided a graphical user interface (GUI) for local LLMs called Open Web UI.",
      teamMembers: ["CSUSM AI Club Members"],
      technologies: ["Ollama", "Open Web UI", "Local LLMs"],
      results:
        "Helped members easily interact with local language models, fostering a deeper understanding of LLMs and their applications.",
    },
  ],
};

function Projects() {
  return (
    <div className="px-4 lg:px-16 lg:py-6 bg-orange-500">
      {/* Featured Projects */}
      <section className="mb-12">
        <h2 className="text-3xl font-bold text-white mb-4">
          Featured Projects
        </h2>
        {projectsData.featuredProjects.map((project, index) => (
          <div key={index} className="bg-white rounded-lg shadow-md p-6 mb-6">
            <h3 className="text-2xl font-semibold mb-2">{project.title}</h3>
            <p className="mb-2">{project.description}</p>
            <p className="mb-2 font-semibold">Problem Statement:</p>
            <p className="mb-2">{project.problemStatement}</p>
            <p className="mb-2 font-semibold">Solution/Approach:</p>
            <p className="mb-2">{project.solution}</p>
            <p className="mb-2 font-semibold">Outcome:</p>
            <p className="mb-2">{project.outcome}</p>
            {project.videoLink && (
              <a
                href={project.videoLink}
                target="_blank"
                rel="noopener noreferrer"
                className="text-blue-500 hover:underline"
              >
                Watch Demo
              </a>
            )}
          </div>
        ))}
      </section>

      {/* Current Projects */}
      <section className="mb-12">
        <h2 className="text-3xl font-bold text-white mb-4">Current Projects</h2>
        <div className="grid gap-6 md:grid-cols-2">
          {projectsData.currentProjects.map((project, index) => (
            <div key={index} className="bg-white rounded-lg shadow-md p-6">
              <h3 className="text-xl font-semibold mb-2">{project.name}</h3>
              <p className="mb-2">{project.overview}</p>
              <p className="mb-2 font-semibold">Status:</p>
              <p className="mb-2">{project.status}</p>
              <p className="mb-2 font-semibold">Opportunities:</p>
              <p className="mb-4">{project.opportunities}</p>
              {project.contact && (
                <a
                  href={project.contact}
                  className="text-blue-500 hover:underline"
                >
                  Contact Us
                </a>
              )}
            </div>
          ))}
        </div>
      </section>

      {/* Past Projects */}
      <section className="mb-12">
        <h2 className="text-3xl font-bold mb-4 text-white">Past Projects</h2>
        {projectsData.pastProjects.map((project, index) => (
          <div key={index} className="bg-white rounded-lg shadow-md p-6 mb-6">
            <h3 className="text-2xl font-semibold mb-2">{project.title}</h3>
            <p className="mb-2">{project.summary}</p>
            <p className="mb-2 font-semibold">Team Members:</p>
            <p className="mb-2">{project.teamMembers.join(", ")}</p>
            <p className="mb-2 font-semibold">Technologies Used:</p>
            <p className="mb-2">{project.technologies.join(", ")}</p>
            <p className="mb-2 font-semibold">Results and Impact:</p>
            <p>{project.results}</p>
          </div>
        ))}
      </section>
    </div>
  );
}

export default Projects;
