import React from 'react';

function AboutSection() {
  // Data for sections
  const sections = [
    {
      title: 'Club Introduction',
      content: 'The AI Club at CSUSM is a community dedicated to exploring, understanding, and advancing the field of Artificial Intelligence (AI) and Machine Learning (ML). We bring together students passionate about technology, innovation, and real-world problem-solving using AI.',
    },
    {
      title: 'Mission Statement',
      content: 'Our mission is to foster a collaborative and inclusive environment that promotes education, innovation, and the practical application of AI and ML. We aim to empower our members to explore cutting-edge technologies, develop valuable skills, and make meaningful contributions to society.',
    },
    {
      title: 'Objectives',
      content: (
        <ul className="list-disc pl-5">
          <li>Promote education and interest in AI and ML through workshops, lectures, and events.</li>
          <li>Encourage practical applications of AI to solve real-world problems.</li>
          <li>Provide a platform for collaboration, knowledge-sharing, and networking with industry professionals.</li>
          <li>Facilitate AI-related projects, research opportunities, and competitions.</li>
          <li>Support members’ academic and professional growth in AI and related fields.</li>
        </ul>
      ),
    },
  ];

  return (
    <div className="px-4 lg:px-16 py-6 bg-gray-100 bg-opacity-70 rounded-lg">
      {/* Header */}
      <h1 className="text-4xl font-bold mb-8 text-center text-orange-600">
        About the AI Club at CSUSM
      </h1>

      {/* Content Sections */}
      <div className="grid gap-8 md:grid-cols-2 lg:grid-cols-3">
        {sections.map((section, index) => (
          <div key={index} className="bg-white shadow-lg rounded-lg p-6">
            <h2 className="text-2xl font-bold mb-4 text-orange-600">
              {section.title}
            </h2>
            <div className="text-gray-700">{section.content}</div>
          </div>
        ))}
      </div>
    </div>
  );
}

export default AboutSection;
