import React from "react";
import NavBar from "./components/NavBar";
import Banner from "./components/Banner";
import AboutSection from "./components/AboutSection";
import Events from "./components/Events";
import Projects from "./components/Projects";
import Footer from "./components/Footer";
import SponsorsPartners from "./components/SponsorsPartners";

function App() {
  return (
    <div className="App">
      <NavBar />
      <div id="home">
        <Banner />
      </div>
      {/* About Section */}
      <div id="about" className="px-4 lg:px-16 bg-orange-500 py-8">
        <AboutSection />
      </div>
      
      {/* Events Section */}
      <div id="events" className="px-4 lg:px-16 bg-cyan-600 bg-opacity-90 text-black py-1">
        <Events />
      </div>
      
      {/* Projects Section */}
      <div id="projects">
        <Projects />
      </div>
      
      {/* Sponsors & Partners Section */}
      <div id="sponsors">
        <SponsorsPartners />
      </div>
      
      {/* Footer */}
      <Footer />
    </div>
  );
}

export default App;
