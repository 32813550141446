import React from 'react';
import { FaInstagram, FaLinkedin, FaDiscord } from 'react-icons/fa'; // Importing icons from react-icons

const Footer = () => {
  return (
    <footer className="bg-orange-500 text-white py-6">
      <div className="container mx-auto px-4 text-center">
        {/* Footer Message */}
        <div className="mb-4">
          <p>&copy; {new Date().getFullYear()} CSUSM AI Club. All rights reserved.</p>
          <p className="mt-2">
            A proud production of{' '}
            <a
              href="https://www.ai-man.info" // Replace with the actual link
              target="_blank"
              rel="noopener noreferrer"
              className="text-white hover:text-gray-400 underline transition-colors"
            >
              Ai-man.info
            </a>
          </p>
        </div>

        {/* Social Media Links */}
        <div className="flex justify-center space-x-6">
          <a
            href="https://www.instagram.com/csusm.ai/"
            target="_blank"
            rel="noopener noreferrer"
            aria-label="Visit our Instagram profile"
            className="text-white hover:text-gray-400 transition-colors"
          >
            <FaInstagram className="w-6 h-6" />
          </a>
          <a
            href="https://www.linkedin.com/groups/14553268/"
            target="_blank"
            rel="noopener noreferrer"
            aria-label="Visit our LinkedIn profile"
            className="text-white hover:text-gray-400 transition-colors"
          >
            <FaLinkedin className="w-6 h-6" />
          </a>
          <a
            href="https://discord.gg/5FkDxwNHMZ"
            target="_blank"
            rel="noopener noreferrer"
            aria-label="Join our Discord server"
            className="text-white hover:text-gray-400 transition-colors"
          >
            <FaDiscord className="w-6 h-6" />
          </a>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
