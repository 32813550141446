import React, { useState, useEffect } from 'react';
import { FaInstagram, FaLinkedin, FaDiscord } from 'react-icons/fa';
import logo from './assets/images/csusmaic_logo.png';

function NavBar() {
  const [isOpen, setIsOpen] = useState(false);
  const [isTop, setIsTop] = useState(true);

  const toggleMenu = () => {
    setIsOpen(!isOpen);
  };

  const handleScroll = (id) => {
    const section = document.querySelector(id);
    if (section) {
      section.scrollIntoView({
        behavior: 'smooth',
        block: 'start',
      });
    }
    setIsOpen(false); // Close the menu on mobile
  };

  useEffect(() => {
    const onScroll = () => {
      setIsTop(window.scrollY === 0);
    };

    window.addEventListener('scroll', onScroll);
    return () => {
      window.removeEventListener('scroll', onScroll);
    };
  }, []);

  return (
    <header
      className={`sticky top-0 flex justify-between items-center p-4 ${
        isTop ? 'bg-orange-500' : 'bg-black bg-opacity-50 hover:bg-opacity-100 hover:bg-orange-500'
      } text-white transition-colors duration-300 z-50`}
    >
      {/* Logo Image */}
      <div className="flex-shrink-0">
        <img src={logo} alt="CSUSM AI Club Logo" className="h-10 w-auto" />
      </div>

      {/* Hamburger Button */}
      <button
        onClick={toggleMenu}
        className="md:hidden block text-white focus:outline-none"
        aria-label="Toggle navigation menu"
      >
        <svg
          className="w-6 h-6"
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 24 24"
          stroke="currentColor"
        >
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="2"
            d={isOpen ? 'M6 18L18 6M6 6l12 12' : 'M4 6h16M4 12h16M4 18h16'}
          />
        </svg>
      </button>

      {/* Navigation Menu */}
      <nav
        className={`${
          isOpen ? 'block' : 'hidden'
        } md:flex md:items-center md:justify-center md:space-x-4 flex-grow`}
      >
        <div className="flex justify-center w-full">
          <ul className="flex flex-col md:flex-row md:space-x-4 space-y-2 md:space-y-0">
            <li>
              <button
                onClick={() => handleScroll('#home')}
                className="hover:text-gray-300 text-lg md:text-xl"
              >
                Home
              </button>
            </li>
            <li>
              <button
                onClick={() => handleScroll('#about')}
                className="hover:text-gray-300 text-lg md:text-xl"
              >
                About Us
              </button>
            </li>
            <li>
              <button
                onClick={() => handleScroll('#events')}
                className="hover:text-gray-300 text-lg md:text-xl"
              >
                Events
              </button>
            </li>
            <li>
              <button
                onClick={() => handleScroll('#projects')}
                className="hover:text-gray-300 text-lg md:text-xl"
              >
                Projects
              </button>
            </li>
            <li>
              <button
                onClick={() => handleScroll('#sponsors')}
                className="hover:text-gray-300 text-lg md:text-xl"
              >
                Sponsors & Partners
              </button>
            </li>
          </ul>
        </div>
      </nav>

      {/* Social Links */}
      <div className="hidden md:flex items-center space-x-4">
        <a
          href="https://www.instagram.com/csusm.ai/"
          target="_blank"
          rel="noopener noreferrer"
          className="hover:text-gray-300"
        >
          <FaInstagram className="w-5 h-5" />
        </a>
        <a
          href="https://www.linkedin.com/groups/14553268/"
          target="_blank"
          rel="noopener noreferrer"
          className="hover:text-gray-300"
        >
          <FaLinkedin className="w-5 h-5" />
        </a>
        <a
          href="https://discord.gg/5FkDxwNHMZ"
          target="_blank"
          rel="noopener noreferrer"
          className="hover:text-gray-300"
        >
          <FaDiscord className="w-5 h-5" />
        </a>
      </div>
    </header>
  );
}

export default NavBar;
